<script>
import {
  SuawMainContent,
  SuawForm,
  SuawSummary,
  SuawInputGroup,
  SuawHeading,
  SuawParagraph,
  SuawCheckbox,
  SuawTextInput,
  SuawSelect,
  SuawButton,
  SuawLabel,
  SuawRadio,
  SuawModal,
  SuawSection,
  SuawSnackbar
} from "@suaw/suaw-component-library";
import { CountryOptions } from "../../operations.gql";
import { required, maxLength, minLength, email } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api.js";

export default {
  name: "DashboardSettingsEdit",
  components: {
    SuawMainContent,
    SuawForm,
    SuawSummary,
    SuawInputGroup,
    SuawHeading,
    SuawParagraph,
    SuawCheckbox,
    SuawTextInput,
    SuawSelect,
    SuawButton,
    SuawLabel,
    SuawRadio,
    SuawModal,
    SuawSection,
    SuawSnackbar
  },
  data() {
    return {
      modalSnackbarMessage: null,
      modalSnackbarType: null,
      showModal: false,
      showPassword: false,
      resultCountryOptions: [
        {
          name: "",
          abbrev: ""
        }
      ],
      editForm: {
        userId: "",
        email: "",
        firstName: "",
        lastName: "",
        isLastNameHidden: null,
        avatarColor: "",
        descriptionJson: null,
        postalCode: "",
        countryAbbrev: "",
        unitSystem: ""
      },
      passwordForm: {
        userId: "",
        oldPassword: "",
        newPassword: "",
        newPasswordRepeat: ""
      }
    };
  },
  computed: {
    userEmail() {
      return this.$auth.user.email;
    },
    userPostalCode() {
      return this.$auth.user.postal_code;
    },
    countryOptions() {
      return this.resultCountryOptions.map(option => ({
        text: option.name,
        value: option.abbrev,
        key: option.abbrev
      }));
    },
    passwordInputType() {
      return this.showPassword ? "text" : "password";
    }
  },
  apollo: {
    resultCountryOptions: {
      query: CountryOptions
    }
  },
  validations: {
    editForm: {
      email: { required, email },
      postalCode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(10)
      }
    },
    passwordForm: {
      oldPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      newPassword: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      newPasswordRepeat: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      }
    }
  },
  mounted() {
    this.editForm = {
      userId: this.$auth.user.id,
      email: this.$auth.user.email,
      firstName: this.$auth.user.first_name,
      lastName: this.$auth.user.last_name,
      isLastNameHidden: this.$auth.user.is_last_name_hidden,
      avatarColor: this.$auth.user.avatar_color,
      descriptionJson: this.$auth.user.description_json,
      postalCode: this.$auth.user.postal_code,
      countryAbbrev: this.$auth.user.country_abbrev,
      unitSystem: this.$auth.user.unit_system
    };
    this.passwordForm = {
      userId: this.$auth.user.id
    };
  },
  methods: {
    validateErrors,
    formFieldState,
    async updateUserProfile() {
      const { userId, email, firstName, lastName, isLastNameHidden, countryAbbrev, postalCode, avatarColor, unitSystem, descriptionJson } = this.editForm;
      const result = await UserApi.updateUserProfile(
        userId,
        email,
        firstName,
        lastName,
        isLastNameHidden,
        countryAbbrev,
        postalCode,
        avatarColor,
        unitSystem,
        null,
        descriptionJson
      );
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async changePassword() {
      const { userId, oldPassword, newPassword } = this.passwordForm;
      const result = await UserApi.changePassword(userId, oldPassword, newPassword);
      return result;
    },
    onCancelClicked() {
      this.$router.push({ name: "DashboardSettingsView" });
    },
    async onConfirmClicked() {
      this.$v.editForm.$touch();
      if (this.$v.editForm.$invalid) {
        this.$root.$emit("universal-error-message", "Please complete required fields of form.");
        return;
      }

      const updateUserProfileResult = await this.updateUserProfile();
      if (!updateUserProfileResult.success) {
        return;
      }

      try {
        this.$router.push({ name: "DashboardSettingsView" });
        window.location.reload(); //forcing reload so when routed to profile view the changes will be reflected
      } catch (error) {
        this.$root.$emit("universal-error-message", "Unable to update your profile. Try again or contact support if issues persist."); //!!not true, is it?
      }
    },
    redirectToSignIn() {
      this.$router.push({
        name: "SignIn",
        query: {
          redirectUrl: `${this.$route.fullPath}`
        }
      });
    },
    onChangePasswordClicked() {
      this.showModal = true;
    },
    onExitModal() {
      this.showModal = false;
    },
    async onSubmitModal() {
      this.$v.passwordForm.$touch();
      if (this.$v.passwordForm.$invalid) {
        this.showSnackbar("Please complete required fields of form.", "critical");
        return;
      }

      if (this.passwordForm.newPassword !== this.passwordForm.newPasswordRepeat) {
        this.showSnackbar("New passwords do not match.", "critical");
        return;
      }

      if (this.passwordForm.oldPassword && this.passwordForm.newPassword) {
        const changePasswordResult = await this.changePassword();
        if (changePasswordResult.success) {
          this.$root.$emit("universal-success-message", "Password successfully changed.");
          this.showModal = false;
        } else {
          this.showSnackbar(changePasswordResult.error, "critical");
        }
      }
    },
    showSnackbar(message, type) {
      this.modalSnackbarMessage = message;
      this.modalSnackbarType = type;
    },
    resetSnackbar() {
      this.modalSnackbarMessage = null;
      this.modalSnackbarType = null;
    },
    onSnackbarConfirm() {
      this.resetSnackbar();
    },
    onSnackbarCancel() {
      this.resetSnackbar();
    }
  }
};
</script>

<template>
  <SuawMainContent size="medium">
    <SuawModal v-if="showModal" modal-title="Change Password" modal-type="confirmation" :toggle-button-confirm="onSubmitModal" :toggle-button-cancel="onExitModal">
      <SuawSnackbar
        v-if="modalSnackbarMessage"
        :text-message="modalSnackbarMessage"
        :type="modalSnackbarType"
        :has-timer="true"
        :timer-amount="8"
        :has-dismiss="false"
        @cancel-click="onSnackbarCancel"
        @action-click="onSnackbarConfirm"
      />
      <SuawTextInput
        id="old-password"
        v-model="passwordForm.oldPassword"
        :type="passwordInputType"
        placeholder="************"
        label="Old Password:"
        is-required
        :state="formFieldState($v, 'passwordForm', 'oldPassword')"
        :error-message="validateErrors($v.passwordForm.oldPassword, 'Old password')"
        @blur="$v.passwordForm.oldPassword.$touch()"
      />
      <SuawTextInput
        id="new-password"
        v-model="passwordForm.newPassword"
        :type="passwordInputType"
        placeholder="************"
        label="New Password:"
        is-required
        :state="formFieldState($v, 'passwordForm', 'newPassword')"
        :error-message="validateErrors($v.passwordForm.newPassword, 'New password')"
        @blur="$v.passwordForm.newPassword.$touch()"
      />
      <SuawTextInput
        id="new-password-repeat"
        v-model="passwordForm.newPasswordRepeat"
        :type="passwordInputType"
        placeholder="************"
        label="Repeat New Password:"
        is-required
        :state="formFieldState($v, 'passwordForm', 'newPasswordRepeat')"
        :error-message="validateErrors($v.passwordForm.newPasswordRepeat, 'Repeat new password')"
        @blur="$v.passwordForm.newPasswordRepeat.$touch()"
      />
      <SuawCheckbox id="show" v-model="showPassword" label="Show Password" />
    </SuawModal>
    <SuawForm>
      <template #form>
        <SuawSummary summary-type="text" heading="Settings" />
        <SuawHeading level="4" content="Account Details" />
        <SuawTextInput
          id="email"
          v-model="editForm.email"
          type="email"
          :placeholder="userEmail"
          label="Email:"
          is-required
          :state="formFieldState($v, 'editForm', 'email')"
          :error-message="validateErrors($v.editForm.email, 'Email')"
          @blur="$v.editForm.email.$touch()"
        />
        <SuawSection>
          <SuawButton class="" size="large" type="ghost-outline" button-text="Change Password" @click="onChangePasswordClicked" />
        </SuawSection>
        <SuawHeading level="4" content="Location" />
        <SuawParagraph text="Entering this information will help us show you the most relevant events near you." />
        <SuawTextInput
          id="postalcode"
          v-model="editForm.postalCode"
          type="text"
          :placeholder="userPostalCode"
          label="Postal Code:"
          is-required
          :state="formFieldState($v, 'editForm', 'postalCode')"
          :error-message="validateErrors($v.editForm.postalCode, 'Postal code')"
          @blur="$v.editForm.postalCode.$touch()"
        />
        <SuawSelect id="country" v-model="editForm.countryAbbrev" label="Country" :options="countryOptions" />
        <SuawLabel label-text="Unit of Length:" size="small" />
        <SuawInputGroup direction="row" :centered="true">
          <SuawRadio id="miles" v-model="editForm.unitSystem" name="chooseUnit" label="Miles" :native-value="'imperial'" />
          <SuawRadio id="kilometers" v-model="editForm.unitSystem" name="chooseUnit" label="Kilometers" :native-value="'metric'" />
        </SuawInputGroup>
      </template>
    </SuawForm>
    <SuawInputGroup field-one-size="0" field-two-size="0" :centered="true">
      <SuawButton size="large" type="secondary-outline" button-text="Cancel" @click="onCancelClicked" />
      <SuawButton size="large" type="primary" button-text="Confirm Changes" @click="onConfirmClicked" />
    </SuawInputGroup>
  </SuawMainContent>
</template>
